import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import "aos/dist/aos.css"


const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 1050px;
  margin: auto;
  padding: 5rem 5rem 0rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: 'Montserrat-SemiBold';
  font-size: 1.3em !important;
  margin-bottom: 0.5em;
  text-align: center;
  max-width: 92.5%;
  margin: auto;
`

const OurHistoryText = styled.p`
  line-height: 1.25em;
  font-size: 1.2em !important;
  text-align: center;
  margin: 1.5em 0 0 
`

export const OurHistory: React.FunctionComponent = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <FlexDiv>
      <OurHistoryWrapper className="ourHistoryWrapper aboutPageWrapper" style={{ padding: '7.5rem 5rem 0rem' }}
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="2000"
      >
        <FlexDiv style={{ flexFlow: 'column' }}>
          <OurHistoryTitle>At Ellicott Realty, we deliver white glove experience that is both trustworthy and genuine. We constantly work to exceed and redefine even the highest expectations.</OurHistoryTitle>
          <OurHistoryText>
            Ellicott Realty is a boutique brokerage specializing in variety of real estate sectors, including residential, commercial, land development, retail, industrial and investment. Our team is well established in York Region and surrounding markets, making us the pre-eminent experts for reaching the GTA's multi faceted suburbs and neighbourhood communities. Our seasoned professionals bring specialized backgrounds with valuable contributions, for service that is both meticulous and thoughtful.
          </OurHistoryText>
        </FlexDiv>
      </OurHistoryWrapper>
      <OurHistoryWrapper className="aboutTxtDetails" style={{ paddingBottom: '5em' }}
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="2000"
      >
        <FlexDiv className="mobileColumn">
          <FlexDiv style={{ flexDirection: 'column', textAlign: 'center' }} className="mobileColumn">

            <h3 style={{ fontSize: '35px', fontWeight: '600', margin: '0' }}>
              $1.2B
            </h3>
            <h4 style={{ margin: '10px auto 45px', fontFamily: 'Montserrat-SemiBold' }}>
              Total Sales Volume
            </h4>
            <p style={{ maxWidth: '77.5%', margin: '0 auto' }}>
              Ellicott Realty has enjoyed continued success by remaining true to its core philosophy of being more knowledgable, dedicated and accessible than its competitors. Having executed
              over $353M in closed business since its inception in 2016, Ellicott Realty has become a multi-faceted organization that's strategically positioned to provide outstanding service within the real estate sector.
            </p>
          </FlexDiv>
          <FlexDiv style={{ flexDirection: 'column', textAlign: 'center' }} className="mobileColumn2">
            <h3 style={{ fontSize: '35px', fontWeight: '600', margin: '0' }}>
              6
            </h3>
            <h4 style={{ margin: '10px auto 45px', fontFamily: 'Montserrat-SemiBold' }}>
              Real Estate Sectors
            </h4>
            <p style={{ maxWidth: '77.5%', margin: '0 auto' }}>
              Ellicott Realty is a boutique brokerage specializing in a variety of real estate sectors, including residential, commercial, land development, retail, industrial and investment. We've designed our business with a multi-pronged approach that allows us to service all things
              real estate. This allows us to imapct added insight when planning all our projects. No matter your requirements, our team can service your needs from A-Z.
            </p>
          </FlexDiv>
          <FlexDiv style={{ flexDirection: 'column', textAlign: 'center' }} className="broadReach">
            <h3 style={{ fontSize: '35px', fontWeight: '600', margin: '0' }}>
              241+M
            </h3>
            <h4 style={{ margin: '10px auto 45px', fontFamily: 'Montserrat-SemiBold' }} > Broad Reach</h4>
            <p style={{ maxWidth: '77.5%', margin: '0 auto' }}>
              When you sell with us, you'll benefit from local and international reach. Our listings are posted on Realtor.ca, Zillow.com and Zolo.ca. In fact Realtor.ca draws in about 50M visitors annually, with 43% initiating their search from Realtor.ca rather then Google. Plus, our listings are distributed through GlobalListings.com offering international exposure to over 241M+ potential buyers in 62 countries.
            </p>
          </FlexDiv>
        </FlexDiv>
      </OurHistoryWrapper>
    </FlexDiv>
  )
}
