import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { OurHistory } from 'components/OurHistory'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { AboutCTA } from 'components/AboutCTA'
import { AboutHero } from 'components/AboutHero'
import { Contact } from 'components/Contact'

const AboutPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <AboutHero />
      <OurHistory />
      <Contact />
    </EllicotLayout>
  )
}

export default AboutPage
